import React, { useState, useCallback } from 'react';
import { Card, Button, Row, Col } from 'antd';
import './AAJourney.css';
import { showErrorToast, getCurrentTime, postMessageListener } from './utils';
import {
  createConsentJourney,
  fetchConsentResults,
} from '../../../../utils/apiService';
import Progress from './Progress';
import {
  logSharedResponse,
} from '../../../../utils';
import CreateConsentForm from './CreateConsentForm';
import AssetDataForm from './AssetDataForm';

const JourneyStatus = {
  READY: 'ready',
  PROGRESS: 'progress',
};

const LoadingStatus ={
  PREPROGRESS:'preprogress',
  PREFETCH: 'prefetch',
  IDLE: 'idle'
}

const AAJourney = () => {
  const [journeyStatus, setJourneyStatus] = useState(JourneyStatus.READY);
  const [postMessages, setPostMessages] = useState([]);
  const [loadingStage, setLoadingStage] = useState(LoadingStatus.IDLE);
  const [consentData, setConsentData] = useState({ journeyId: '', url: '' });

  const updatePostMessages = useCallback(event => {
    if (event.origin !== window.location.origin) {
      const { eventLabel, metadata } = event.data;
      setPostMessages(prev => [
        { time: getCurrentTime(), data: eventLabel },
        ...prev,
      ]);
    }
  }, []);

  const handleConsentResponse = res => {
    const { journeyId, vendorData } = res.data;
    if (journeyId && vendorData?.redirectUrl) {
      setConsentData({ journeyId, url: vendorData.gwRedirectUrl });
      setJourneyStatus(JourneyStatus.PROGRESS);
      postMessageListener(true, updatePostMessages);
    }
  };

  const handleStartJourney = async (userId, phone, pan) => {
    try {
      setLoadingStage(LoadingStatus.PREPROGRESS);
      const response = await createConsentJourney(userId, phone, pan);
      handleConsentResponse(response);
    } catch (err) {
      showErrorToast('Consent Creation Error', err.response?.data);
    } finally {
      setLoadingStage(LoadingStatus.IDLE);
    }
  };

  const handleDataFetch = async (journeyId) => {
    try {
      setLoadingStage(LoadingStatus.PREFETCH);
      const res = await fetchConsentResults(journeyId);
      logSharedResponse('Asset Data', res.data);
    } catch (err) {
      showErrorToast('Data Fetch Error', err.response?.data);
    } finally {
      setLoadingStage(LoadingStatus.IDLE);
    }
  };

  const closeJourney = () => {
    setJourneyStatus(JourneyStatus.READY);
    setPostMessages([]);
    setConsentData({ journeyId: '', url: '' });
    postMessageListener(false, updatePostMessages);
  };

  const getScene = () => {
    switch (journeyStatus) {
      case 'progress':
        return (
          <Progress consentData={consentData} postMessages={postMessages} />
        );

      default:
        return (
          <Row>
            <Col sm={24} xl={12}>
              <div className="actions-container">
                <div>
                  <h1 className="action-head">Consent Creation Journey</h1>
                  <CreateConsentForm
                    onSubmit={handleStartJourney}
                    loading={loadingStage === LoadingStatus.PREPROGRESS}
                  />
                </div>
              </div>
            </Col>
            <Col sm={24} xl={12} style={{ flexGrow: 1, alignItems: 'stretch' }}>
              <div className="actions-container">
                <Row className="mb12">
                  <h1 className="action-head">Get Asset Data</h1>
                  <AssetDataForm
                    onSubmit={handleDataFetch}
                    loading={loadingStage === LoadingStatus.PREFETCH}
                  />
                </Row>
              </div>
            </Col>
          </Row>
        );
    }
  };

  return (
    <div className="container">
      <Card
        title="Account Aggregator Journey"
        extra={
          journeyStatus !== 'ready' && (
            <Button
              type="danger"
              ghost
              style={{ marginLeft: 4 }}
              onClick={closeJourney}
            >
              Reset
            </Button>
          )
        }
      >
        {getScene()}
      </Card>
    </div>
  );
};

export default AAJourney;
