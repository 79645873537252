import React from 'react';
import { Button, Form, Input } from 'antd';

const AssetDataForm = ({ form, onSubmit, loading }) => {
  const { getFieldDecorator, validateFields } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { journey_id } = values;
        onSubmit(journey_id);
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Journey Id">
        {getFieldDecorator('journey_id', {
          rules: [
            {
              required: true,
              message: 'Journey Id is required !',
            },
          ],
          validateTrigger: 'onSubmit'
        })(
          <Input
            placeholder="605b01d2832ac170727e6890"
          />,
        )}
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          className="btn"
          type="secondary"
          htmlType="submit"
          loading={loading}
        >
          Fetch
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedAssetDataForm = Form.create({ name: 'fetch-assets' })(
  AssetDataForm,
);

export default WrappedAssetDataForm;
